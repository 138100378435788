<template>
	<div class="theme-dashboard-view">
		<!-- Dashboard Title -->
		<h1>{{ $t("dashboard_title") }}</h1>

		<div class="d-flex flex-wrap mx-3">
			<!-- Dashboard buttons -->
			<div
				class="col-xl-3 col-lg-4 col-md-5 col-12 mx-lg-4 mx-md-2 my-4 rounded theme-background-primary d-flex justify-content-center align-items-center dbutton"
				style="min-height: 250px; font-size: 80px; color: white"
				v-for="(button, index) in buttons"
				:key="index"
				@click="this.$router.push(button.path)"
			>
				<div class="text-center">
					<i :class="button.icon"></i>
					<h2>{{ button.title }}</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Services
import AuthService from "@/services/auth.js";

export default {
	name: "DashboardHome",
	data() {
		return {
			buttons: [
				{
					icon: "fas fa-search",
					title: this.$t("dashboard_inventory_button"),
					path: "/search",
				},
				{
					icon: "fas fa-folder-open",
					title: this.$t("dashboard_category_button"),
					path: "/category",
				},
				{
					icon: "fas fa-shopping-cart",
					title: this.$t("dashboard_orders_button"),
					path: "/orders",
				},
				{
					icon: "fas fa-truck",
					title: this.$t("dashboard_drivers_button"),
					path: "/drivers",
				},

				{
					icon: "fas fa-cog",
					title: this.$t("dashboard_settings_button"),
					path: "/settings",
				},
				{
					icon: "fas fa-hands-helping",
					title: this.$t("dashboard_help_button"),
					path: "/help",
				},
			],
		};
	},
	mounted() {
		AuthService.verifyToken(localStorage.getItem("user").token);
	},
};
</script>

<style scoped>
.dbutton {
	cursor: pointer;
}
.dbutton:hover {
	transform: scale(1.05);
	border: 2px black solid;
}
</style>
